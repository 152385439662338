import {
  Stack,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function WithBackgroundImage() {
  return (
    <Flex
      w={'full'}
      h={useBreakpointValue({ base: '20vh',   md: '40vh' })}
      backgroundImage={
        'url(/assets/images/1636616125921.webp)'
      }
      backgroundRepeat={'no-repeat'}
      backgroundSize={'contain'}
      backgroundPosition={'center center'}>
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.100, transparent)'}>
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
            Therapeutic intervention Care &amp; Control Training
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
}