import {
  Flex,
  Heading,
  Stack,
  Text,
  Divider
} from '@chakra-ui/react';
export default function MainText() {
  return (
    <Stack minH={'20vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={1} flex={1} w={'full'} align={'left'} justify={'center'}>
        <Stack w={'full'} maxW={'full'} align={'left'} justify={'center'}>
          <br/><br/>
          <Heading color="primary.700" id="Level1" textAlign="center" as="h2" size="xl" >Therapeutic intervention Care &amp; Control Training </Heading>
          <Text fontSize="xl">Based in the UK, Therapeutic intervention Care &amp; Control Training (TiCC) offers a wealth of experience and expertise in Conflict Management. SPEAR system. Personal Safety/Self-defence. Disengagement/Breakaway Techniques. Therapeutic Management of Aggression. Physical intervention/SafeHoldingsTechniques. </Text>
          <Text fontSize="xl">We deliver our training on a National and International level. </Text>
          <Text fontSize="xl">Principal trainers/tutors hold English National Board (ENB A74) certificates and teaching qualifications and have extensive experience working in the National Health Services (UK) and Private sectors. </Text>
          <Text fontSize="xl">We are committed to the short and long term needs of our clients. TiCC aims to provide the highest quality training and consultancy to meet your needs. </Text>
          <Text fontSize="xl">The Royal College of Psychiatry (RCP 1997), the Royal College of Nursing (RCN), the Health &amp; Safety Executive (HSE 1989), and the UKCC (UKCC 2002) have all issued major pieces of work addressing issues in work place violence and there have been publications and guidelines from the National Institute for Mental Health in England (NIMHE 2004) and the National Institute for health and care Excellence (NICE 2015). Our training follows the advice and recommendations laid down in these pieces of work and continue to develop. </Text>
          <Text fontSize="xl">All our training courses are accredited by British Self-defence Governing Body (BSDGB) and approved by General Services Association (GSA) and are available for delivery direct to your organisation or place-of-work, please contact us for details. </Text>
          <Divider />
        </Stack>
      </Flex>
    </Stack>
  );
}