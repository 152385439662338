import {
  Stack,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Divider
} from '@chakra-ui/react';
export default function Trainer() {
  return (
    <Stack minH={'20vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={1} flex={1} w={'full'} align={'left'} justify={'center'}>
        <Stack w={'full'} maxW={'full'} align={'left'} justify={'center'}>
          <br/><br/>
          <Heading color="primary.700" id="Trainers" textAlign="center"  as="h2" size="xl" >TRAINERS/TUTORS<br/>
          Patrick Law  (Training Consultant/ Principal Trainer) </Heading>
          <Text fontSize="xl">Holds: </Text>
          <UnorderedList padding="5" fontSize="xl">
            <ListItem>Adult &amp; Further Education Teachers Certificate </ListItem>
            <ListItem>British Self-Defence Governing Body (BSDGB) Advanced Certificate Instructor </ListItem>
            <ListItem>English National Board (ENB) A74 Training the Trainer Certificate </ListItem>
            <ListItem>English National Board (ENB) 998 Teaching and Assessing Certificate </ListItem>
            <ListItem>First Aid at Work Certificate </ListItem>
            <ListItem>General Services Association (GSA) Tutor Certificate  </ListItem>
            <ListItem>Registered Mental Health Nurse (RMN) </ListItem>
            <ListItem>Spear system Instructor </ListItem>
            <ListItem>Emergency response belt (ERB) trainer </ListItem>
          </UnorderedList>
          <Text fontSize="xl">Patrick has been involved in personal safety and self-defence training for over thirty years. He has extensive experience as a qualified mental health practitioner and manager in psychiatric intensive care units. He has been training nurses and other health care professionals in the prevention and management of violence/physical intervention both in the NHS and private sectors. </Text>
          <Text fontSize="xl">Experienced in teaching abroad i.e Iceland, Canada, China, Macau, he has an ongoing commitment to teach Therapeutic Management of Aggression and Violence/Physical intervention in Hong Kong and China. </Text>
          <Text fontSize="xl">Patrick has been delivering Training the Trainers Courses for South London and Maudsley NHS Trust (validated by Salford University, Manchester), Hong Kong and China for a number of years. He is also an honourable advisor to two hospitals in China, Shenzhen and Heibei. </Text>
          <Divider />
        </Stack>
      </Flex>
    </Stack>
  )
}