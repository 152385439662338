import {
  Stack,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Divider
} from '@chakra-ui/react';
export default function Courses() {
  return (
    <Stack minH={'20vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={1} flex={1} w={'full'} align={'left'} justify={'center'}>
        <Stack w={'full'} maxW={'full'} align={'left'} justify={'center'}>
          <br/><br/>
          <Heading color="primary.700" id="Level1" textAlign="center" as="h2" size="xl" >(LEVEL 1) <br/>
          CONFLICT MANAGEMENT/TACTICAL COMMUNICATION SKILL </Heading>
          <Text fontSize="xl">This course is for any personnel who deal with the public, the course provides the ability to deal effectively with an angry, stressed or difficult client. With emphasis on verbal and non-verbal de-escalation techniques, tactical communication skills and the utilisation of life skills, you will be more confident and capable in dealing with these situations.  </Text>
          <Text fontSize="xl">Participants will be able to: </Text>
          <UnorderedList padding="5" fontSize="xl">
          <ListItem>Understand the difference between anger, aggression and violence. </ListItem>
          <ListItem>Understand the difference between hostile aggression and instrumental aggression. </ListItem>
          <ListItem>Understand the model of aggression. </ListItem>
          <ListItem>Explain the importance and use of listening skills. </ListItem>
          <ListItem>Understand the meaning and importance of the components of communication. </ListItem>
          <ListItem>Demonstrate and evaluate key elements of verbal communication in reducing confrontation. </ListItem>
          <ListItem>Demonstrate and evaluate key elements of non-verbal communication in reducing confrontation. </ListItem>
          <ListItem>Describe the environmental factors likely to reduce the risk of violence in the work place. </ListItem>
          <ListItem>Recognise the danger signs of Aggression. </ListItem>
          <ListItem>Describe the usual four social boundaries referred to in proxemics. </ListItem>
          <ListItem>Describe de-escalation verbal and non-verbal strategies. </ListItem>
          <ListItem>Explain the use of 'positive-phasing' as a verbal strategy. </ListItem>
          <ListItem>Understand the use of tactical communication skill. </ListItem>
          <ListItem>Describe and evaluate how the use of the Tactical Communications model decreases the likelihood of the escalation of a confrontation into physical violence. </ListItem>
          <ListItem>Describe and evaluate five communication tools. </ListItem>
          <ListItem>Describe and evaluate the five-step positive cycle. </ListItem>
          <ListItem>Describe and evaluate the 'Conflict Resolution Model'.  </ListItem>
          </UnorderedList>
          <Divider />
          <Heading color="primary.700" textAlign="center"  as="h2" size="xl" > (LEVEL 1) <br/>
          LEGAL  </Heading>
          <Text fontSize="xl">This course is for all personnel who deal directly with the public. The course examines the legal defence system in U.K and the Human Right Act 1998 issues regarding the use of force.  </Text>
          <Text fontSize="xl">Participants will be able to: </Text>
          <UnorderedList padding="5" fontSize="xl" >
          <ListItem>Explore and clarify personal and professional values in relation to violence management. </ ListItem>
          <ListItem>Understand key legal terms which are in common use. </ ListItem>
          <ListItem>Understand how law is made. </ ListItem>
          <ListItem>Understand the key differences between criminal and civil law. </ ListItem>
          <ListItem>Understand the Human Right Act 1998 relating to the use of force. </ ListItem>
          <ListItem>Explain the Common Law principle of 'use of force'. </ ListItem>
          <ListItem>Understand the Criminal Law Act S.3.1 1967 the 'use of force'. </ ListItem>
          <ListItem>Appreciate that an individual may be held personally liable for his/her actions. </ ListItem>
          <ListItem>Understand the requirements for compensation claims and the law of negligence. </ ListItem>
          <ListItem>Give an account of the factors used to determine the concept of 'Reasonable force'. </ ListItem>
          </UnorderedList>
          <Divider />
          <Heading color="primary.700" textAlign="center" as="h2" size="xl" >(LEVEL 1) <br/>
          POST INCIDENT DE-BRIEFING </Heading>
          <Text fontSize="xl">This course is for personnel who may be involved in an incident and need to facilitate the post-incident review. No matter how careful you are, you cannot eliminate the risks altogether, and you may still fall victim of an attack. In the event of either being a victim or a witness to a violent incident, the information on this course will prove invaluable. </Text>
          <Text fontSize="xl">Participants will be able to: </Text>
          <UnorderedList padding="5" fontSize="xl">
          <ListItem>Appreciate the importance of post-incident review for staff and patient(s). </ListItem>
          <ListItem>Understand the process of post incident de-briefing. </ListItem>
          <ListItem>Understand their own and others role in post-incident debriefing. </ListItem>
          <ListItem>Understand the effects of trauma and guilt often felt by victims of violent crime. </ListItem>
          <ListItem>Be aware of the sources of help for victims following an incident.  </ListItem>
          </UnorderedList>
          <Divider />
          <Heading color="primary.700" id="Level2" textAlign="center"  as="h2" size="xl" >(LEVEL 2) <br/>
          DISENGAGEMENT/BREAKAWAY TECHNIQUES </Heading>
          <Text fontSize="xl">This course is for any personnel who work in any environment where they own a defined 'duty of care' to their clients. The course covers basic verbal de-escalation skills, use of body language to avoid confrontation and the recognition of warning signs. The physical techniques shown enable the victim to break free and get to an area of safety or summon further assistance.  </Text>
          <Text fontSize="xl">These Disengagement/Breakaway techniques do not replace our right to defend ourselves. </Text>
          <Text fontSize="xl">Participants will be able to: </Text>
          <UnorderedList padding="5" fontSize="xl">
          <ListItem>Describe theories behind the defence of a grab.</ListItem>
          <ListItem>Describe theories behind the defence of a strike.</ListItem>
          <ListItem>Demonstrate an understanding of Proxemics.</ListItem>
          <ListItem>Demonstrate the importance of a &#39;safe stance&#39;.</ListItem>
          <ListItem>Utilise the techniques to safely and effectively extricate themselves from a range of attacks.</ListItem>
          <ListItem>Describe the legal concept of 'reasonable force' and ‘self defence’.</ListItem>
          </UnorderedList>
          <Text fontSize="xl" fontWeight="bold">A certificate of attendance is issued to all those attend the course. </Text>
          <Divider />
          <Heading color="primary.700" textAlign="center"  as="h2" size="xl" >(LEVEL 2) <br/>
          PERSONAL SAFETY/SELF-DEFENCE </Heading>
          <Text fontSize="xl">This course is for personnel who work alone and have direct contact with the public. The course will heighten awareness and clarifies risk assessment for lone workers when visiting, travelling and at work. The course also provides practical skills that minimise the risk of injury and enables escape from various attacks. </Text>
          <Text fontSize="xl">The participants will be able to: </Text>
          <UnorderedList padding="5" fontSize="xl">
          <ListItem>Understand the Law relating to self-defence. </ListItem>
          <ListItem>Understand the risks of working alone. </ListItem>
          <ListItem>The process of risk assessment when working alone. </ListItem>
          <ListItem>Understand the circumstances in which a physical response should be used to deal with a violent situation. </ListItem>
          <ListItem>Understand how to use distraction techniques effectively as a means of escape or extrication. </ListItem>
          <ListItem>Understand the principles of personal safety in relation to travelling on foot, by public transport, by car, by taxi. </ListItem>
          <ListItem>Understand the effects of alcohol or drugs on an assailant. </ListItem>
          <ListItem>Appreciate the changes in physical response which may be necessary when dealing with effects of alcohol or drugs. </ListItem>
          <ListItem>Describe the 'striking arc'. </ListItem>
          <ListItem>Demonstrate the defensive stance. </ListItem>
          <ListItem>Demonstrate techniques to escape from a front strangle hold. </ListItem>
          <ListItem>Demonstrate techniques to escape from attacks from the front (punching and kicking) </ListItem>
          <ListItem>Demonstrate techniques to escape from a headlock from behind. </ListItem>
          <ListItem>Demonstrate techniques of ground defence. </ListItem>
          <ListItem>Demonstrate techniques of escape from a static knife attack.  </ListItem>
          </UnorderedList>
          <Text fontSize="xl" fontWeight="bold">A certificate of attendance is issued to all those attend the course. </Text>
          <Divider />
          <Heading color="primary.700" id="Level3" textAlign="center"  as="h2" size="xl" >(LEVEL 3) <br/>
          THERAPEUTIC MANAGEMENT OF AGGRESSION AND VIOLENCE. PHYSICAL INTERVENTION/SAFE HOLDINGS </Heading>
          <Text fontSize="xl">This course is for personnel who work in clinical area and have direct contact with clients. The course has two parts -theoretical and practical- and has strong emphasis on prevention strategies and de-escalation skills. It also includes:  communication skills; legal and ethical issues; case studies; post incident de-briefing; documentation and reporting; observation and monitoring; risk assessment and postural/position asphyxia; disengagement techniques;   incident management and staff replacement. </Text>
          <Text fontSize="xl">As physical intervention often unavoidable, participants are taught to use hierarchy holds to immobilise an individuals limbs through natural movement - achieved by deploying a two/three-person staff team. This approach minimises the risk of injury and also preserves the dignity of those involved.  </Text>
          <Text fontSize="xl">An annual two-day refresher is required for staff to update the skills acquired on this course.  </Text>
          <Text fontSize="xl">Participants will be able to: </Text>
          <UnorderedList padding="5" fontSize="xl">
          <ListItem>Discuss possible causes of violence. </ListItem>
          <ListItem>Understand the model of aggression. </ListItem>
          <ListItem>Describe and evaluate the prevention of violence strategies. </ListItem>
          <ListItem>Understand legal and ethical issues in the management of violence. </ListItem>
          <ListItem>Demonstrate the use of verbal and non-verbal de-escalation strategies. </ListItem>
          <ListItem>Demonstrate the ability to use effective communication skills. </ListItem>
          <ListItem>Describe options for dealing with language barriers. </ListItem>
          <ListItem>Describe the most commonly encountered Cultural &amp; Gender Sensitivity issues. </ListItem>
          <ListItem>Demonstrate an understanding of the importance of protection of airways. </ListItem>
          <ListItem>Demonstrate an understanding of the risk of sudden death through positional asphyxia or excited/agitated delirium. </ListItem>
          <ListItem>Describe the observation and monitoring procedures of sedated patients. </ListItem>
          <ListItem>Understand the importance and the method of documenting an incident. </ListItem>
          <ListItem>Understand the process of reviewing the incident both with patient and staff. </ListItem>
          <ListItem>Describe the process of post-incident review of the patient's management and treatment. </ListItem>
          <ListItem>Demonstrate the ability to apply the appropriate disengagement techniques in a variety of situations. </ListItem>
          <ListItem>Demonstrate an understanding of the principles of team work. </ListItem>
          <ListItem>Demonstrate the ability to safely escort a patient in a supportive hold. </ListItem>
          <ListItem>Have the ability to assess and utilise hierarchy holds. </ListItem>
          <ListItem>Demonstrate the ability to apply the appropriate restraint techniques in a variety of situations. </ListItem>
          <ListItem>Describe the key factors in good incident management.   </ListItem>
          </UnorderedList>
          <Text fontSize="xl" fontWeight="bold">This is a five- day course, which includes Conflict Management Training. </Text>
          <Text fontSize="xl" fontWeight="bold">A certificate of competency is issued to those who meet the required standard of assessment. </Text>
          <Text fontSize="xl" fontWeight="bold">Please note that the above is only an example of the type of courses that we run and can be adapted to your needs </Text>
          <Divider />
          <Heading color="primary.700" id="Spear" textAlign="center"  as="h2" size="xl" >SPEAR SYSTEM</Heading>
          <Text fontSize="xl">This system was developed by Tony Blauer. it incorporates with all the physical skills training. </Text>
          <Text fontSize="xl">The SPEAR system utilizes the speed and reliability of the Startle-Flinch mechanism to convert the sudden attack into a tactical response. </Text>
          <Text fontSize="xl">This will improve reaction time during a confrontation. </Text>
          <Text fontSize="xl">It's the only personal defence system that has been vetted by medical experts on three continents. </Text>
          <Text fontSize="xl">This system will make you, your family, your team, and your colleagues safer. </Text>
          <Divider />
        </Stack>
      </Flex>
    </Stack>
  )
}