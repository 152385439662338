import React from "react";
import Header from "./components/Header";
import SplitScreen from "./components/Hero";
import Trainer from "./components/Trainer";
import Courses from "./components/Courses";
import MainText from "./components/MainText";
import Contact from './components/Contact.js';
import { Container, Box } from "@chakra-ui/react";
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


const App = () => (
  <BrowserRouter>
  <Container maxW="container.xl">
    <Box padding="4">
    <Header />
    <SplitScreen/>
    <Routes >
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Trainer" element={<Trainer />} />
      <Route path="/Courses" element={<Courses />} />
      <Route path="/" element={<MainText />} />
    </Routes >
    </Box>
  </Container>
  </BrowserRouter>
)

export default App