import {
    Stack,
    Flex,
    Heading,
    Text,
    Link
  } from '@chakra-ui/react';

export default function Contact() {
    return (
        <Stack minH={'20vh'} direction={{ base: 'column', md: 'row' }}>
            <Flex p={1} flex={1} w={'full'} align={'left'} justify={'center'}>
                <Stack w={'full'} maxW={'full'} align={'left'} justify={'center'}>
                    <br/><br/>
                    <Heading color="primary.700" id="Level1" textAlign="center" as="h2" size="xl" >CONTACT US</Heading><br/>
                    <Text fontSize="xl" textAlign="center">Please Contact us if you would like more information or to book a course.</Text>
                    <Text fontSize="xl" textAlign="center">Email Address: <Link color="primary.700" href="Mailto:ticctraining@gmail.com">ticctraining@gmail.com</Link> </Text>
                    <Text fontSize="xl" textAlign="center">Phone number: +44(0)7957442342</Text>
                </Stack>
            </Flex>
        </Stack>
    )
}
